import React, { useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function TableChecklist({columns, rows, children, ...props}) {
  const [checked, setChecked] = useState([])
  return (
    <TableContainer> {/* table scrolls horizontally */}
      <Table {...props}>
        {columns?.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              {columns.map(({header, headerProps}, index) => (
                <TableCell key={index} {...headerProps}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows?.map((row, index) => {
            const isSelected = checked.includes(index)
            return (
              <TableRow
                key={index}
                hover
                aria-checked={isSelected}
                onClick={() => setChecked(prev => {
                  if (isSelected)
                    return prev.filter(ind => ind !== index)
                  else
                    return [...prev, index]
                })}
                role="checkbox"
                sx={{ cursor: 'pointer' }}
              >
                <TableCell padding="checkbox"><Checkbox color="default" size="small" checked={isSelected} /></TableCell>
                {row.map((children, col_index) => {
                  const props = columns?.[col_index].props
                  return (
                    <TableCell
                      key={col_index}
                      role="cell"
                      {...props}
                      sx={{textDecoration: isSelected && "line-through", ...props?.sx}}
                    >
                      {children}
                    </TableCell>
                  )
                })}
              </TableRow>
            )})}
            {children}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
