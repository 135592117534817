// Should handle most of the basic CRUD operations

import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPremium, GridActionsCellItem, GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiRef, useKeepGroupedColumnsHidden, gridExpandedSortedRowIdsSelector } from '@mui/x-data-grid-premium';
import SettingsIcon from '@mui/icons-material/Settings';
import PrintIcon from '@mui/icons-material/Print';
import SkeletonLoadingOverlay from '../components/SkeletonLoadingOverlay';
import { Badge, Snackbar } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LocationPositionPickerDialogForSampleDataGrid } from 'components/SampleLocationPicker/LocationPickerDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios';
import moment from 'moment';

export default function SampleDataGrid({api, columns, AddForm, onSelectionModelDidChange, getPrintLabel, actions = [], initialState, ...props}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]); // IDs of selected rows, ordered by selection sequence
  const handleSelectionModelChange = (newSelectionModel) => {
    setRowSelectionModel(newSelectionModel)
    onSelectionModelDidChange && onSelectionModelDidChange(newSelectionModel)
  }
  useEffect(() => {
    if (data?.length && searchParams.get("id")) {
      const id_query = parseInt(searchParams.get("id"))
      setRowSelectionModel([id_query])
      apiRef.current.scrollToIndexes({rowIndex: gridExpandedSortedRowIdsSelector(apiRef).findIndex(id => id === id_query)})
      setSearchParams()
    }
  }, [data, searchParams, setSearchParams])
  const [showForm, setShowForm] = useState() // index, row. index = -1 is reserved for create
  const [message, setMessage] = useState()
  // Create
  const handleCreate = (newRow) => {
    const max_id = data == null ? 0 : data.reduce((cumVal, curVal) => Math.max(cumVal, curVal.id), -Infinity) // gets max id in data
    const temp_id = Math.floor(100000 * Math.random()) + max_id + 1 // +1 guarantees different from data, random makes unlikely collison with another add
    axios.post(api.create, newRow)
      .then(({data : {id}}) => setData(prev => prev.map(x => x.id === temp_id ? {...x, id} : x)))
    setData(prev => [...prev, {id: temp_id, ...newRow}])
  }
  // Read
  useEffect(() => {
    const loadStuff = () => {
      axios.get(api.read).then(({data : {data : newData}}) => {
        setData(newData)
        if (newData.length === 0)
          setShowForm({index: -1}) // force add new row if empty data
      });
    };
    loadStuff();
    const fetchInterval = setInterval(loadStuff, 60000);
    return () => clearInterval(fetchInterval);
  }, [api]);
  // Update
  const processRowUpdate = async (updatedRow, originalRow) => {
    var updates = Object.fromEntries(Object.entries(updatedRow).filter(([key, value]) => !key.startsWith('_') && (value !== originalRow?.[key])))
    axios.post(api.update, {id: updatedRow.id, ...updates})
    return updatedRow
  }
  // Delete
  const handleDelete = (id) => {
    axios.post(api.delete, {id})
    setData(prev => prev.filter(x => x.id !== id));
  }
  // Ubiquitinylate
  const handleUbiquitinylate = (ids) => {
    axios.post(api.ubiquitinylate, {ids})
      .then(({status}) => status === 200 && setMessage({message: `Ubiquitinylated ${ids.length} sample${ids.length === 1 ? '' : 's'}`, open: true}))
    setData(prev => prev.map(x => ids.includes(x.id) ? {...x, ubiquitin: true} : x));
  }
  // Print labels
  const handlePrintLabels = (ids) => {
    if (!getPrintLabel)
      return
    if (!ids || ids.length === 0)
      return
    const dataToPrint = ids.map(x => getPrintLabel(data.find(y => y.id === x)))
    const stringToPrint = dataToPrint.map(x => x.join("\t")).join("\n")
    if (navigator.clipboard) {
      navigator.clipboard.writeText(stringToPrint)
      setMessage({message: `${ids.length > 1 ? "Labels" : "Label"} copied to clipboard`, open: true})
    }
    else
      setMessage({message: "Can't access clipboard", open: true})
  }
  // Export as TSV
  const handleCopy = (ids) => {
    if (!ids || ids.length === 0)
      return
    const stringToPrint = apiRef.current.getDataAsCsv({getRowsToExport: () => ids, delimiter: "\t"})
    if (navigator.clipboard) {
      navigator.clipboard.writeText(stringToPrint)
      setMessage({message: "TSV copied to clipboard", open: true})
    }
    else
      setMessage({message: "Can't access clipboard", open: true})
  }
  const ACTIONS_LABELS = (params) => getPrintLabel ? [
    <GridActionsCellItem icon={<PrintIcon />} onClick={() => handlePrintLabels([params.id])} label="Print Label" showInMenu />,
    <GridActionsCellItem icon={<Badge badgeContent={rowSelectionModel.length}><PrintIcon /></Badge>} onClick={() => handlePrintLabels(rowSelectionModel)} label="Print Labels" showInMenu disabled={rowSelectionModel.length === 0} />,
    <GridActionsCellItem icon={<Badge badgeContent={rowSelectionModel.length}><ContentCopyIcon /></Badge>} onClick={() => handleCopy(rowSelectionModel)} label="Copy" showInMenu disabled={rowSelectionModel.length === 0} />,
  ] : []
  // For hiding column of the group
  const apiRef = useGridApiRef();
  const initialStateWithGroupedColsHidden = useKeepGroupedColumnsHidden({apiRef, initialState});
  return <>
    <DataGridPremium
      apiRef={apiRef}
      initialState={{density: "compact", ...initialStateWithGroupedColsHidden}}
      rows={data || []}
      loading={data == null}
      sx={{border: "none"}}
      columns={[...columns,
        GRID_CHECKBOX_SELECTION_COL_DEF,
        {
          field: 'actions',
          renderHeader: () => <SettingsIcon fontSize="small" color="action" />,
          type: 'actions',
          width: 50,
          getActions: params => [
            <GridActionsCellItem icon={<AddIcon />} onClick={() => setShowForm({index: -1})} label="New" showInMenu disabled={!AddForm} />,
            ...actions.map((action, index) => ({action, index})).filter(({action}) => action.shouldShow ? action.shouldShow(params) : true).map(({action, index}) => {
              if (action.multiple)
                return (
                  <GridActionsCellItem
                    key={index}
                    icon={<Badge badgeContent={rowSelectionModel.length}>{action.icon}</Badge>}
                    onClick={() => setShowForm({index, row_id: (action.multiple === "only") ? rowSelectionModel : (rowSelectionModel.length > 0 ? rowSelectionModel : [params.id])})} // preserve order of selection
                    label={action.label}
                    showInMenu
                    disabled={(action.multiple === "only") && (rowSelectionModel.length === 0)}
                  />
                )
              else
                return (
                  <GridActionsCellItem
                    key={index}
                    icon={action.icon}
                    onClick={() => setShowForm({index, row_id: params.id})}
                    label={action.label}
                    showInMenu
                  />
                )
            }),
            <GridActionsCellItem icon={<DeleteIcon />} onClick={() => handleDelete(params.id)} label="Discard" showInMenu />,
            <GridActionsCellItem icon={<Badge badgeContent={rowSelectionModel.length}><DeleteIcon /></Badge>} onClick={() => rowSelectionModel.forEach(id => handleDelete(id))} label="Discard Selected" showInMenu disabled={rowSelectionModel.length === 0} />,
            ...(api.ubiquitinylate ? [<GridActionsCellItem icon={<Badge badgeContent={(rowSelectionModel.length > 1) && rowSelectionModel.length}><DeleteOutlineIcon /></Badge>} onClick={() => handleUbiquitinylate(rowSelectionModel.length > 0 ? rowSelectionModel : [params.id])} label="Ubiquitinylate" showInMenu disabled={(rowSelectionModel?.length > 0) && !rowSelectionModel.includes(params.id)} />] : []),
            ...ACTIONS_LABELS(params)
          ]
        }]}
      hideFooter
      checkboxSelection
      disableRowSelectionOnClick={rowSelectionModel.length === 0}
      onRowSelectionModelChange={handleSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      processRowUpdate={processRowUpdate}
      slots={{
        loadingOverlay: SkeletonLoadingOverlay,
      }}
      cellSelection
      {...props}
    />
    {AddForm && <AddForm open={showForm?.index === -1} onClose={() => setShowForm(null)} onSubmit={newRow => handleCreate(newRow)} />}
    {actions.map((action, index) => (
      <action.Form
        key={index}
        open={showForm?.index === index}
        row={(showForm?.index === index) && 
          (Array.isArray(showForm?.row_id) ? 
            showForm.row_id.map(id => data.find(x => x.id === id)) : 
            data.find(x => x.id === showForm.row_id))
        }
        onClose={() => setShowForm(null)}
        setData={setData}
        showMessage={(message) => setMessage({message, open: true})}
        // Don't pass api.update. Instead, use a hook to pass it to forms that need it.
      />
    ))}
    {message && <Snackbar autoHideDuration={6000} {...message} onClose={() => setMessage(prev => ({...prev, open: false}))} />}
  </>;
}

export function GRID_SAMPLE_LOCATION_COL_DEF() {
  const [containers, setContainers] = useState([])
  useEffect(() => {
    axios.get("/api/wetlab/container/read").then(({data : {data}}) => setContainers(data))
  }, []);
  return ([
    {
      field: 'location_box',
      headerName: "Box",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: containers.map(x => ({value: x.id, label: x.name})),
      valueFormatter: value => containers && value && containers.find(x => x.id === value)?.name
    },
    {
      field: 'location_pos',
      headerName: "Position",
      width: 80,
      editable: true,
      type: "number",
      renderEditCell: (params) => (
        <LocationPositionPickerDialogForSampleDataGrid {...params} />
      ),
    }
  ])
}

export const GRID_SAMPLE_DATE_COL_VAL = (field) => ({
  valueGetter: value => value && moment(value),
  valueSetter: (value, row) => ({...row, [field]: value && value.toISOString()}),
})