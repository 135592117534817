import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { SEQUENCING_SERVICE_PROVIDERS, formatStringForGenewiz } from 'utils/utils';
import axios from 'axios';

const formatPlasmidName = plasmid => plasmid?.colony_id ? `${plasmid.name}-${plasmid.colony_id}` : plasmid?.name
const formatPlasmidLength = plasmid => {
  if (!plasmid?.length) return "8001-10000"
  else if (plasmid.length < 501) return "<501"
  else if (plasmid.length <= 1000) return "501-1000"
  else if (plasmid.length <= 2000) return "1001-2000"
  else if (plasmid.length <= 4000) return "2001-4000"
  else if (plasmid.length <= 6000) return "4001-6000"
  else if (plasmid.length <= 8000) return "6001-8000"
  else if (plasmid.length <= 10000) return "8001-10000"
  else return ">10000"
}

export default function SangerOrderForm({open, row : rows, onClose, setData, showMessage, ...props}) {
  const [formData, setFormData] = useState({service_provider: SEQUENCING_SERVICE_PROVIDERS[0], sample_ready: moment()})
  const [waiting, setWaiting] = useState(false)
  const handleClose = () => {
    if (!waiting)
      onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setWaiting(true)
    if (formData.service_provider === "Genewiz (Copy to Clipboard)") {
      // process genewiz manually because their API is complicated, and their web interface is hard to scrape
      navigator.clipboard.writeText(rows.map(row => `${formatStringForGenewiz(formatPlasmidName(row.sample))}\t${formatPlasmidLength(row.sample)}\t${formatStringForGenewiz(row.primer?.name)}`).join("\n"))
      showMessage?.(`Info for ${rows.length === 1 ? "this sample" : `${rows.length} samples`} copied to clipboard. Please paste into Genewiz order form`)
      axios.post("/api/wetlab/protocols/sanger/update", rows.map(row => ({id: row.id, service_provider: formData.service_provider, date_ordered: formData.sample_ready.toISOString(), sample_index: rows.indexOf(row) + 1})))
      setData?.(prev => prev.map(x => rows.includes(x) ? ({...x, service_provider: formData.service_provider, date_ordered: formData.sample_ready.toISOString(), sample_index: rows.indexOf(x) + 1}) : x))
      handleClose()
    }
    else {
      // open popup directly in response to user action to avoid popup blockers
      var w = window.open('', "Order Form", "menubar=0,location=0,height=600,width=1100")
      axios.post("/api/wetlab/protocols/sanger/order", {reaction_ids: rows.map(row => row.id), ...formData})
        .then(({data : {body_to_print, url_to_print, order_id, error}, status}) => {
          setWaiting(false)
          if (status !== 200 || error) {
            showMessage?.(error || "Failed to place order")
            return
          }
          w.focus()
          if (body_to_print) {
            w.document.write(body_to_print)
            w.print()
          }
          else if (url_to_print)
            w.location.href = url_to_print
          else
            w.document.write("No order form to print")
        showMessage?.(`Order ${order_id} placed successfully`)
        setData?.(prev => prev.map(x => rows.includes(x) ? ({...x, service_provider: formData.service_provider, order_id, date_ordered: formData.sample_ready.toISOString(), sample_index: rows.indexOf(x) + 1}) : x))
        handleClose()
      })
      .catch((error) => {
        setWaiting(false)
        showMessage?.("Failed to place order")
        console.error(error)
      })
    }
  }
  return (
    <Dialog open={!!open} onClose={handleClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Place Sanger Order</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="standard" disabled={waiting}>
            <InputLabel id="provider-label">Service Provider</InputLabel>
            <Select labelId="provider-label" label="Service Provider" value={formData?.service_provider} onChange={e => setFormData(prev => ({...prev, service_provider: e.target.value}))}>
              {SEQUENCING_SERVICE_PROVIDERS.map(provider => 
                <MenuItem key={provider} value={provider}>{provider}</MenuItem>
              )}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Sample Ready"
              value={formData?.sample_ready || null}
              onChange={newValue => setFormData(prev => ({...prev, sample_ready: newValue}))}
              disabled={waiting}
            />
          </LocalizationProvider>
          <DialogContentText>
            Clicking Submit will use {formData?.service_provider}'s API to place an order for th{rows?.length > 1 ? "ese" : "is"} sample{rows?.length > 1 && "s"}:
            <ol>
              {rows?.map?.((row, i) => <li key={i}>{formatPlasmidName(row.sample)}, {row.primer?.name}</li>)}
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={waiting}>Cancel</Button>
          <LoadingButton type="submit" loadingPosition="end" endIcon={<SendIcon />} loading={waiting}>Submit</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
