import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
  Grid,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import { nullableDateComparator, sampleNameComparator, truncateString } from '../../utils/utils';
import SampleLocationPicker from 'components/SampleLocationPicker/SampleLocationPicker';
import { NANODROP_FORM_MENUACTION_DEF } from 'forms/NanodropForm';
import { SANGER_FORM_MENUACTION_DEF } from 'forms/SangerForm';
import SyncIcon from '@mui/icons-material/Sync';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import DNAVerified from 'components/DNAVerified';
import SampleNameField from 'components/SampleNameField';
import TransformForm from 'forms/TransformForm';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import LentivirusProductionForm from 'forms/LentivirusProductionForm';
import { GOLDENGATE_FORM_MENUACTION_DEF } from 'forms/GoldenGateForm';

function AddForm({open, onClose, onSubmit, ...props}) {
  const defualt_new_plasmid = {name: "pSPS", date: moment(), volume: 50}
  const [plasmid, setPlasmid] = useState(defualt_new_plasmid);
  const handleClose = () => {
    onClose();
    setPlasmid(prev => ({location_box: prev?.location_box, ...defualt_new_plasmid}));
  }
  const handleSubmit = (event) => {
    onSubmit({...plasmid, volume: plasmid.volume ? plasmid.volume * 1000 : undefined, concentration: plasmid.concentration ? plasmid.concentration * 1000 : undefined})
    handleClose()
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Plasmid"
      plasmid={plasmid}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

export function AddFormForm({open, onClose, onSubmit, plasmid, onChange, title, error, autoFocus = "Name", isPlasmid = true, ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus={autoFocus === "Name"}
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={plasmid?.name || ""}
            error={error?.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <Grid container spacing={1}>
            <Grid item>
              <TextField
                autoFocus={autoFocus === "Concentration"}
                margin="dense"
                label="Concentration"
                variant="standard"
                inputProps={{inputMode: "decimal"}}
                sx={{input: {textAlign: "right"}}}
                value={plasmid?.concentration || ""}
                error={error?.concentration}
                onChange={e => onChange({concentration: e.target.value})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">ng/μL</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                autoFocus={autoFocus === "Volume"}
                margin="dense"
                label="Volume"
                variant="standard"
                inputProps={{inputMode: "decimal"}}
                sx={{input: {textAlign: "right"}}}
                value={plasmid?.volume || ""}
                error={error?.volume}
                onChange={e => onChange({volume: e.target.value})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">μL</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          {isPlasmid && <TextField
            autoFocus={autoFocus === "Colony"}
            margin="dense"
            label="Colony"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            value={plasmid?.colony_id || ""}
            error={error?.colony_id}
            onChange={e => onChange({colony_id: e.target.value})}
          />}
          <SampleLocationPicker location_box={plasmid?.location_box} location_pos={plasmid?.location_pos} onChange={onChange} sample_type={isPlasmid ? "plasmid" : "dna"} />
          <TextField
            autoFocus={autoFocus === "Description"}
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={plasmid?.description || ""}
            error={error?.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY"
              label="Date"
              value={plasmid?.date || null}
              error={error?.date}
              onChange={(newValue) => onChange({date: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function Plasmids() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const columns_hidden_mobile = isMobile && {description: false, volume: false}
  return (
    <>
    <SampleDataGrid
      api={{
        create: "/api/wetlab/plasmids/add",
        read: "/api/wetlab/plasmids/get",
        update: "/api/wetlab/plasmids/update",
        delete: "/api/wetlab/plasmids/discard",
        ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
      }}
      AddForm={AddForm}
      actions={[
        PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/plasmids/update"),
        NANODROP_FORM_MENUACTION_DEF("/api/wetlab/plasmids/update"),
        SANGER_FORM_MENUACTION_DEF,
        {
          label: "Transform",
          icon: <ControlPointDuplicateIcon />,
          Form: TransformForm,
        },
        {
          label: "Make Lentivirus",
          icon: <CoronavirusIcon />,
          Form: LentivirusProductionForm,
        },
        GOLDENGATE_FORM_MENUACTION_DEF,
      ]}
      columns={[
        {
          field: 'experiment',
          headerName: 'Experiment',
          width: 80,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 180,
          editable: true,
          sortComparator: sampleNameComparator,
          renderCell: params => <DNAVerified name={params.row.name} sequence_verified={params.row.sequence_verified} />
        },
        {
          field: 'sequence',
          headerName: 'Sequence',
          editable: true,
        },
        {
          field: 'colony_id',
          headerName: 'Colony',
          width: 80,
          editable: true,
          type: "number",
        },
        {
          field: 'sequence_verified',
          headerName: 'Sequence Verified',
          width: 100,
          editable: false,
          renderCell: params => {
            if (params.value === null)
              return <SyncIcon />
            else if (params.value === true)
              return <CheckIcon />
            else
              return <CloseIcon />
          }
        },
        {
          field: 'is_assembly_product',
          headerName: 'Assembly Product',
          width: 100,
          editable: false,
          valueFormatter: value => value === null ? "" : (value ? "yes" : "no"),
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        ...GRID_SAMPLE_LOCATION_COL_DEF(),
        {
          field: 'date',
          type: 'date',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date'),
          valueFormatter: value => value && moment(value).format("M/D/YYYY"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'mass',
          headerName: "μg",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: (_, row) => row.concentration && row.volume && row.concentration * row.volume / 1000000000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1})
        },
        {
          field: 'concentration',
          headerName: "ng/μL",
          width: 80,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, concentration: value && value * 1000}),
        },
        {
          field: 'volume',
          headerName: "μL",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
        },
        {
          field: 'length',
          headerName: "bp",
          width: 60,
          editable: true,
          type: "number",
        }]}
        initialState={{
          sorting: {
            sortModel: [
              { field: "experiment", sort: "desc" },
              { field: "name", sort: "asc" },
              { field: "colony_id", sort: "asc" },
              { field: "date", sort: "desc" },
            ],
          },
          columns: {
            columnVisibilityModel: {
              sequence_verified: false, // not visible, other cols remain visible
              is_assembly_product: false,
              mass: false,
              sequence: false,
              length: false,
              ...columns_hidden_mobile
            },
          },
        }}
      getPrintLabel={(row) => 
        row.description ? 
        [
          `PLASMID ${row.name}${row.colony_id ? `, col ${row.colony_id}` : ''}`,
          truncateString(row.description, 35),
          `Simon, ${(row.concentration / 1000)?.toFixed(1)}ng/μL, ${moment(row.date).format("M/D/YYYY")}`
        ] : [
          `PLASMID ${row.name}`, `${(row.concentration / 1000)?.toFixed(1)}ng/μL${row.colony_id ? `, colony ${row.colony_id}` : ''}`, `Simon ${moment(row.date).format("M/D/YYYY")}`
        ]
      }
      />
    </>)
}