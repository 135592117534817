import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

export default function PlasmidAssemblyForm({open, row : rows, onClose, setData, onCommit, showMessage, api_update, ...props}) {
  const default_assembly = {method: "gibson", product: {name: "pSPS"}, fragments: {}, volume: 15, incubation_time: 60}
  const [assembly, setAssembly] = useState(default_assembly)
  const handleClose = () => {
    onClose();
    setAssembly(default_assembly)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    axios.post(api_update, assembly)
      .then(({status}) => status === 200 && showMessage("Plasmid assembly saved"))
    // onCommit?.(assembly)
    // setData?.(prev => prev.map(x => ({...x, concentration: concentrations[x.id] || x.concentration})))
    handleClose();
  }
  const pmol_from_length = (bp, is_backbone = false) => {
    if (is_backbone)
      return 0.01
    if (!bp)
      return undefined
    return (bp <= 220) ? 0.05 : 0.02
  }
  useEffect(() => {
    if (rows)
    {
      // Assume backbone is longest fragment or first selected
      const backbone_length = Math.max(...rows.map(row => row.length))
      const backbone_index = backbone_length > 0 ? rows.findIndex(row => row.length === backbone_length) : 0
      setAssembly(prev => ({...prev, fragments: {...Object.fromEntries(rows.map((row, i) => [row.id, pmol_from_length(row.length, i === backbone_index)])), ...prev.fragments}}))
    }
  }, [rows])
  useEffect(() => {
    // Get recommended plasmid sequence
    if (!rows || !assembly.method) {
      setAssembly(prev => ({...prev, product: {...prev.product, sequence: ""}}))
      return
    }
    axios.get(`/api/wetlab/protocols/assembly/params?method=${assembly.method}&fragments=${rows.map(row => row.id).join(",")}`).then(({data : {error, sequence}}) => {
      if (error)
        setAssembly(prev => ({...prev, product: {...prev.product, sequence: ""}}))
      else
        setAssembly(prev => ({...prev, product: {...prev.product, sequence}}))
    })
  }, [rows, assembly.method])
  if (!open || !rows)
    return null
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{rows.length === 1 ? `Assemble ${rows[0].name} into Plasmid` : `Assemble ${rows.length} Fragments into Plasmid`}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="standard">
            <InputLabel id="rxntype-label">Method</InputLabel>
            <Select labelId="rxntype-label" label="Method" value={assembly.method} onChange={e => setAssembly(prev => ({...prev, method: e.target.value}))}>
              <MenuItem value={"gibson"}>Gibson</MenuItem>
              <MenuItem value={"ligation_t7"}>T7 Ligation</MenuItem>
            </Select>
          </FormControl>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Product"
            fullWidth
            variant="standard"
            value={assembly?.product?.name || ""}
            onChange={e => setAssembly(prev => ({...prev, product: {...prev.product, name: e.target.value}}))}
          />
          {rows.map(row =>
            <TextField
              key={row.id}
              margin="dense"
              label={`Amount of ${row.name}`}
              fullWidth
              variant="standard"
              inputProps={{inputMode: "decimal"}}
              sx={{input: {textAlign: "right"}}}            
              value={assembly.fragments[row.id] || ""}
              onChange={e => setAssembly(prev => ({...prev, fragments: {...prev.fragments, [row.id]: e.target.value}}))}
              InputProps={{
                endAdornment: <InputAdornment position="end">pmol</InputAdornment>,
              }}
            />
            )}
            <TextField
              margin="dense"
              label="Volume"
              fullWidth
              variant="standard"
              inputProps={{inputMode: "decimal"}}
              sx={{input: {textAlign: "right"}}}            
              value={assembly.volume || ""}
              onChange={e => setAssembly(prev => ({...prev, volume: e.target.value}))}
              InputProps={{
                endAdornment: <InputAdornment position="end">μL</InputAdornment>,
              }}
            />
            <TextField
              margin="dense"
              label="Incubation"
              fullWidth
              variant="standard"
              inputProps={{inputMode: "decimal"}}
              sx={{input: {textAlign: "right"}}}            
              value={assembly.incubation_time || ""}
              onChange={e => setAssembly(prev => ({...prev, incubation_time: e.target.value}))}
              InputProps={{
                endAdornment: <InputAdornment position="end">min</InputAdornment>,
              }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="standard"
              value={assembly?.product?.description || ""}
              onChange={e => setAssembly(prev => ({...prev, product: {...prev.product, description: e.target.value}}))}
            />
            <TextField
              margin="dense"
              label="Sequence"
              fullWidth
              variant="standard"
              value={assembly?.product?.sequence || ""}
              onChange={e => setAssembly(prev => ({...prev, product: {...prev.product, sequence: e.target.value}}))}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Done</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const PLASMIDASSEMBLY_FORM_MENUACTION_DEF = (api_update) => ({
  label: "Assemble into Plasmid",
  icon: <DataUsageIcon />,
  Form: (props) => <PlasmidAssemblyForm api_update={api_update} {...props} />,
  multiple: true,
})
