import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import TableChecklist from 'components/TableChecklist';

export default function MixStep({product, ingredients, ...props}) {
  const total_volume = ingredients.reduce((prevVal, curVal) => prevVal + curVal.volume.value, 0)
  if (ingredients.some(ingredient => ingredient.volume.unit !== "μL"))
    throw new Error("All ingredient volumes must be in μL")
  return (
    <TableChecklist
      size="small"
      columns={[
        {header: "Name", props: {component: "th", scope: "row"}},
        {header: "μL", headerProps: {align: "right"}, props: {align: "right"}},
      ]}
      rows={ingredients.map(row => [row.ingredient.name, row.volume.value.toFixed(2)])}
      {...props}
    >
      <TableRow>
        <TableCell />
        <TableCell>Total ({product.name})</TableCell>
        <TableCell align="right">{total_volume.toFixed(2)}</TableCell>
      </TableRow>
    </TableChecklist>
  )
}