import React from 'react'
import { Tooltip } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

export default function DNAVerified({name, sequence_verified}) {
  if (sequence_verified === true)
    return <>{name}<Tooltip title="Sequence verified"><VerifiedIcon sx={{fontSize: 14, marginLeft: 1}} /></Tooltip></>
  else if (sequence_verified === false)
    return <>{name}<Tooltip title="Failed sequence verification"><ErrorIcon color="error" sx={{fontSize: 18, marginLeft: 1}} /></Tooltip></>
  else
    return <>{name}</>
}
